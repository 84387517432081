import AdyenCheckout from '@adyen/adyen-web';
import { Loader, TransactionStatus } from '@k3imagine/self-serve-components';
import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { createGlobalStyle } from 'styled-components';
import { useFetch } from 'use-http';
import useGlobalState from '../../state/useGlobalState';
import {
  AdyenPaymentResultCode as Result,
  AdyenSessionConfig,
  PaymentStatus
} from '../../types';

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;

  flex-direction: column;
  justify-content: center;
`;

const Centered = styled.div`
  text-align: center;
`;

export default function AdyenRedirect({ location }: RouteComponentProps) {
  const qs = location?.search;
  const params = new URLSearchParams(qs);
  const locationId = params.get('locationId'); // TODO: handle missing location ID
  const basketId = params.get('basketId');
  const redirectResult = params.get('redirectResult');

  const { t } = useTranslation();
  const globalState = useGlobalState();

  const [error, setError] = useState<Error | null>(null);
  const { data, loading, error: sessionError, get } = useFetch<
    AdyenSessionConfig
  >();

  const e = sessionError || error;

  const navigateToPaymentStatus = async (
    status: PaymentStatus,
    message?: string
  ) => {
    await navigate(`/?${locationId}`, {
      replace: true,
      state: {
        redirectTo: 'payment-summary',
        source: 'adyen-redirect',
        paymentStatus: status,
        basketId,
        message
      }
    });
  };

  const refresh = () => {
    window.location.reload();
  };

  const createCheckout = useCallback(() => {
    const id = data?.id;
    const environment = data?.environment;
    const clientKey = data?.clientKey;

    if (!environment || !clientKey) {
      return;
    }

    const run = async () => {
      const checkout = await AdyenCheckout({
        environment,
        clientKey,
        session: { id },
        onPaymentCompleted: (response: any) => {
          switch (response.resultCode) {
            case Result.Authorised:
              return navigateToPaymentStatus(PaymentStatus.Success);

            case Result.Received:
            case Result.Pending:
              return navigateToPaymentStatus(PaymentStatus.Pending);

            default:
              // TODO: pull failure details from TopGw
              return navigateToPaymentStatus(PaymentStatus.Failure);
          }
        },
        onError: (err: Error) => {
          setError(err);
        }
      });

      checkout.submitDetails({ details: { redirectResult } }); // we finalize the redirect flow with the received payload
    };

    run();
  }, [data?.environment, data?.clientKey, redirectResult]);

  useEffect(() => {
    if (!basketId?.length) {
      return;
    }

    get(`v1.0/paymentprovider/adyen/${basketId}/session`);
  }, [basketId]);

  useEffect(() => {
    if (loading || sessionError) return;

    createCheckout();
  }, [data?.environment, data?.clientKey, data?.id, loading, sessionError]);

  const GlobalStyles = createGlobalStyle`
    @import url(${globalState.visualProfileFonts?.stylesheetUrl});

    * {
      font-family: ${globalState.visualProfileFonts?.name ||
        'Source Sans Pro'}, 'Source Sans Pro';
    }

    body {
      margin: 0;
    }
  `;
  const render = () => {
    if (loading) {
      return (
        <Centered>
          <Loader width={200} color="black" />
        </Centered>
      );
    }

    if (e) {
      return (
        <TransactionStatus
          status="Failed"
          title={t('Basket.PaymentFailed')}
          description={e.message}
          buttonText={t('TryAgain')}
          onButtonClicked={refresh}
        />
      );
    }

    return (
      <Centered>
        <Loader width={200} color="black" />
        <p>{t('WaitForRedirect')}</p>
      </Centered>
    );
  };

  return (
    <>
      <GlobalStyles />
      <Container>{render()}</Container>
    </>
  );
}
